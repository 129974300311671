(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

jQuery(function ($) {
  var $hamburger = $('#hamburger');
  var $navDetail = $('#navDetail');
  var $productLink = $('#productLink');
  var $product = $('#product');
  $(function () {
    var windowWidth = $(window).width();

    if (windowWidth >= 1024) {
      navDetialHeight();
    }

    if (windowWidth < 768) {
      navProductLinkToggle();
    }

    hamburger();
  });
  $(window).on('resize', function () {
    $(function () {
      var windowWidth = $(window).width();

      if (windowWidth >= 1024) {
        navDetialHeight();
      }
    });
  });

  function hamburger() {
    $hamburger.on('click', function () {
      $(this).toggleClass("hamburger--on");
      $navDetail.toggleClass("nav-detail--on");
    });
  }

  function navDetialHeight() {
    var windowHeight = $(window).height();

    if (windowHeight < 450) {
      $navDetail.addClass("scroll-y");
    } else {
      $navDetail.removeClass("scroll-y");
    }
  }

  function navProductLinkToggle() {
    $productLink.on('click', function (e) {
      e.preventDefault();
      $(this).toggleClass("open");
      $product.toggleClass("open");
    });
  } //topへスムースscroll


  $(function () {
    var pageTop = $('#toTop');
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 300) {
        pageTop.fadeIn();
      } else {
        pageTop.fadeOut();
      }
    });
    pageTop.on("click", function () {
      $('body, html').animate({
        scrollTop: 0
      }, 500);
      return false;
    });
  });
  $(function () {
    //URLのハッシュ値を取得
    var urlHash = location.hash; //ハッシュ値があればページ内スクロール

    if (urlHash) {
      //スクロールを0に戻す
      $('body,html').stop().scrollTop(0);
      setTimeout(function () {
        //ロード時の処理を待ち、時間差でスクロール実行
        scrollToAnker(urlHash);
      }, 10);
    } //通常のクリック時


    $('a[href^="#"]').click(function () {
      //ページ内リンク先を取得
      var href = $(this).attr("href"); //リンク先が#か空だったらhtmlに

      var hash = href == "#" || href == "" ? 'html' : href; //スクロール実行

      scrollToAnker(hash); //リンク無効化

      return false;
    }); // 関数：スムーススクロール
    // 指定したアンカー(#ID)へアニメーションでスクロール

    function scrollToAnker(hash) {
      var target = $(hash);
      var position = target.offset().top; //            $('body,html').stop().animate({scrollTop: position}, 500);

      var speed = 500;

      if ($(window).width() > 767) {
        var adjust = 60;
      } else {
        var adjust = 60;
      }

      var position_adjust = position - adjust;
      $("html, body").animate({
        scrollTop: position_adjust
      }, speed, "swing");
    } //faqページのスマホ時のselect


    $(function () {
      $('#faqNav').on('change', function (e) {
        var val = $(this).val();
        val = '#' + val;
        scrollToAnker(val);
      });
    });
  });
  $(function () {
    $(".p-faq-block").find('.__q').on('click', function () {
      $(this).toggleClass("show");
      $(this).next('.__answer').toggleClass("show");
    });
  }); // 商品詳細

  $(function () {
    // ID追加
    if ($('#itemDetail-cont').length) {
      var itemId = $('#itemDetail-cont').find('form.shopping_form').find('input[name="ITEM_NO"]').val();
      var itemIdHtml = '<div id="itemId">' + itemId + '</div>';
      $("#pi_cart").prepend(itemIdHtml);
    } // 詳細ページランキング画像の非表示


    if ($("#pi_cart").length) {
      $(".icons").find('.ico_itemType').find('li').each(function (each) {
        var imgAlt = $(this).find('img').attr("alt");

        if (imgAlt.startsWith('ランキング')) {
          //true
          $(this).addClass("single-icon-ranking");
        }
      });
    } // スマホの時、 下のタグを任意のデフォルトではなく任意の場所に出力する


    if ($(window).width() < 768) {
      var itemTag = $('.itemDetail-wrap-first').find('.description').find('.item-tag');
      var itemTag1 = itemTag.clone().addClass("clone");
      var itemTag2 = itemTag.clone().addClass("clone"); // 上部料金下に挿入

      $("#pi_cart").find('table.price').after(itemTag1); // 下部料金下に挿入

      $(".itemDetail-wrap-first").find('.description').find("h2.single-detail__ttl").after(itemTag2);
    }
  }); //商品一覧ページへのタグ追加
  //仕様上一覧ページへのタグの表記はなしになったのでコメントアウト
  // $(function () {
  //     $(".column4").each(function (each) {
  //         let innerCatchText = $(this).find('.itemThumb-wrap-right').find('.inner-catch').text();
  //         console.log(innerCatchText);
  //         if (innerCatchText != "") {
  //             // console.log('true');
  //             let ary = innerCatchText.split(',');
  //             console.log(ary);
  //             let printText = '<ul class="archive-tag">';
  //             $.each(ary, function (index, val) {
  //                 if (val == "SALE") {
  //                     printText += '<li class="red">SALE</li>';
  //                 } else if (val == "新商品") {
  //                     printText += '<li class="yellow">新商品</li>';
  //                 } else if (val == "売り切れ"){
  //                     printText += '<li class="blue">売り切れ</li>';
  //                 }
  //             });
  //             printText += "</ul>";
  //             console.log(printText);
  //             $(this).find('.itemThumb-wrap-right').append(printText);
  //         }
  //     })
  // });
  // animation

  $(function () {
    $(".animation").each(function () {
      var $animation = $(this),
          animationOffsetTop = $animation.offset().top;
      var adjust = -200;

      function scrollAnimation() {
        if ($(window).scrollTop() > animationOffsetTop - $(window).height() - adjust) {
          $($animation).addClass('fuwa');
        }
      }

      $(window).on('scroll', function () {
        scrollAnimation();
      });
      scrollAnimation();
    });
  });
});

},{}]},{},[1]);
